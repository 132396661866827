const ErrorPage = () => {
  return (
    <>
      <section class="error-page-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 position-relative">
              <div class="error-content text-center pt-170 pb-170">
                <h1>
                  4<span>0</span>4
                </h1>
                <h3>
                  Oops! <span>Page Not Found</span>
                </h3>
                <p>The page you were looking for could not be found.</p>
                <a class="btn-md bo-ra-5" href="index.html">
                  Return Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorPage;
