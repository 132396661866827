import React, { useEffect } from "react";
import ProfileCard from "./components/ProfileCard";
import SponsorCard from "./components/SponsorCard";

var seniorManagementTeam = [
  {
    name: "Shree Maljibhai Devjibhai Desai",
    position: "President",
    imageLocation: "/css/img/volunteers/Maljibhai.jpg",
  },
  {
    name: "Shree Haribhai Veljibhai Chaudary",
    position: "Vice President",
    imageLocation: "/css/img/volunteers/Haribhai.jpg",
  },
  {
    name: "Shree Hasmukhbhai Babubhai Patel",
    position: "Secretary",
    imageLocation: "/css/img/volunteers/Hasmukhbhai.jpg",
  },
];

var volunteerList = [
  {
    name: "Mr. Vijaybhai Chaudhary",
    position: "Director",
    imageLocation: "/css/img/volunteers/Vijaybhai.jpg",
  },
  {
    name: "Kanaiyalal P. Patel",
    position: "Accountant",
    imageLocation: "/css/img/volunteers/Kanubhai.jpg",
  },
  {
    name: "Jayeshbhai D. Chaudhary",
    position: "Social Mobiliser",
    imageLocation: "/css/img/volunteers/Jayesh.jpg",
  },
  {
    name: "Bhavnaben J. Chaudhary",
    position: "Social Mobiliser",
    imageLocation: "/css/img/volunteers/Bhavana.jpg",
  },
];

var trusteeList = [
  {
    name: "Shree Maljibhai Devjibhai Desai",
    position: "President",
    imageLocation: "/css/img/volunteers/Maljibhai.jpg",
  },
  {
    name: "Dr. Mihirbhai Dwarkadas Joshi",
    position: "Trustee",
    imageLocation: "/css/img/volunteers/DrMihirbhai.jpg",
  },
  {
    name: "Shree Ishwarbhai Joitaram Patel",
    position: "Trustee",
    imageLocation: "/css/img/volunteers/Ishwarbhai.jpg",
  },
  {
    name: "Shree Hasmukhbhai Babubhai Patel",
    position: "Secretary",
    imageLocation: "/css/img/volunteers/Hasmukhbhai.jpg",
  },
  {
    name: "Shree Amrutlal B. Patel",
    position: "Trustee",
    imageLocation: "/css/img/volunteers/Amrutbhai.jpg",
  },
  {
    name: "Shree Vasantbhai S. Gadhvi",
    position: "Trustee",
    imageLocation: "/css/img/volunteers/Vasantbhai.jpg",
  },
  {
    name: "Shree Kiritkumar Kanubhai Dudhat",
    position: "Trustee",
    imageLocation: "/css/img/volunteers/Kiritbhai.jpg",
  },
  {
    name: "Shree Sanjay Raghuvir Chaudhary",
    position: "Trustee",
    imageLocation: "/css/img/volunteers/Sanjaybhai.jpg",
  },
  {
    name: "Shree Rajendra Amulakhbhai Khimani",
    position: "Trustee",
    imageLocation: "/css/img/volunteers/Rajendrabhai.jpg",
  },
  {
    name: "Shree Haribhai Veljibhai Chaudary",
    position: "Trustee",
    imageLocation: "/css/img/volunteers/Haribhai.jpg",
  },
];

var sponsorList = [
  {
    imageLocation: "/css/img/brand-logo/GMDC.png",
    length: 2,
  },
  {
    imageLocation: "/css/img/brand-logo/GWRDC.png",
    length: 3,
  },
  {
    imageLocation: "/css/img/brand-logo/Dairy.jpg",
    length: 3,
  },
  {
    imageLocation: "/css/img/brand-logo/SardarSarovar.jpg",
    length: 3,
  },
];

function Aboutus() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "MRCF | About Us";
    // window.jQuery('.knob').each(function() {
    //     var $this = window.jQuery(this),
    //         knobVal = $this.attr('data-rel');

    //     $this.knob({
    //         'draw': function() {
    //             window.jQuery(this.i).val(this.cv + '%')
    //         }
    //     });

    //     $this.appear(function() {
    //         window.jQuery({
    //             value: 0
    //         }).animate({
    //             value: knobVal
    //         }, {
    //             duration: 2000,
    //             easing: 'swing',
    //             step: function() {
    //                 $this.val(Math.ceil(this.value)).trigger('change');
    //             }
    //         });
    //     }, { accX: 0, accY: -150 });
    // });
    window.jQuery(".counter").each(function () {
      var $this = window.jQuery(this),
        countTo = $this.attr("data-count");

      window.jQuery({ countNum: $this.text() }).animate(
        {
          countNum: countTo,
        },

        {
          duration: 10000,
          easing: "linear",
          step: function () {
            $this.text(Math.floor(this.countNum));
          },
          complete: function () {
            $this.text(this.countNum);
            //alert('finished');
          },
        }
      );
    });
    window.jQuery("#slider-1").nivoSlider({
      effect: "random",
      slices: 15,
      boxCols: 8,
      boxRows: 4,
      animSpeed: "600",
      pauseTime: "6000000",
      startSlide: 0,
      directionNav: 1,
      controlNav: 1,
      controlNavThumbs: false,
      pauseOnHover: false,
      manualAdvance: false,
      prevText:
        '<span class="left"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
      nextText:
        '<span class="right"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
    });
  }, []);
  return (
    <>
      {/* <h1><center>Page Under Construction</center></h1> */}
      <section class="page-title-area">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 position-relative">
              <div class="page-title text-center">
                <h1>About us</h1>
              </div>
              <div class="breadcrumbs-area  text-center">
                <ol class="breadcrumbs">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li class="active">About us</li>
                </ol>
              </div>
              <div class="back-home">
                <a href="/">
                  <i class="fa fa-arrow-left" aria-hidden="true"></i> BACK TO
                  HOME
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about-content-area pt-90 pb-60">
        <div class="container">
          <div class="row gy-2">
            <div class="section-title text-center">
              <h2>
                Our <span>Infrastructure</span>
              </h2>
              <img src="/css/img/title-bottom.png" alt="" />
            </div>
            <div class="col-lg-6">
              <div class="about-content">
                <p>
                  On March 2, 2015, the <b>"Moti Bhavan"</b> (office building of the
                  Shree Motibhai R. Chaudhary Foundation) was inaugurated. Moraribapu,
                  a famous humanitarian and spiritual counselor, graced the
                  occasion with his presence. The event reflected the
                  organization's commitment to supporting marginalized
                  populations, promoting education, healthcare, and long-term
                  development. Moraribapu's inspiring words resonated with the
                  audience, creating a renewed sense of purpose and compassion.
                  <br />
                  <br />
                  The <b>"Moti Bhavan"</b> stands as a tangible manifestation of the
                  collective efforts to bring positive change to the world. The
                  NGO set out on a transforming path with Moraribapu's
                  blessings, ready to inspire and uplift numerous individuals
                  via their persistent effort and unalterable commitment. The
                  ground floor of this three-story <b>"Moti Bhavan"</b> comprises three
                  office cabins, one conference hall, and open space. The first
                  level has six rooms, each with its own toilet and bath. The
                  second level also has six rooms, each with its own toilet and
                  bath. These rooms are utilized for a variety of training and
                  capacity-building programs.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-image">
                <img
                  src="/css/img/about/OO01.jpg"
                  //   style={{ marginBottom: "2vh" }}
                  alt=""
                />
                {/* <br />
                <img src="/css/img/about/OO02.jpg" alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="finish-project ptb-114">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-6">
              <div class="single-project text-center">
                <h2 class="counter" data-count="52">
                  0
                </h2>
                <h3>Completed Projects</h3>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="single-project text-center">
                <h2 class="counter" data-count="22">
                  0
                </h2>
                <h3>Creative Materials</h3>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="single-project text-center">
                <h2 class="counter" data-count="26">
                  0
                </h2>
                <h3>Work Experience (Yrs.)</h3>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="single-project text-center">
                <h2 class="counter" data-count="1">
                  0
                </h2>
                <h3>Professional awards</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="our-volunteers-area pt-80 pb-90">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="section-title text-center">
                <h2>
                  OUR <span>TRUSTEE</span>
                </h2>
                <img src="/css/img/title-bottom.png" alt="" />
                <p>
                  Our trustee plays a crucial role in the functioning and
                  success of our NGO. As a dedicated and passionate individual,
                  our trustee upholds the organization's values and mission,
                  ensuring that they are translated into actionable initiatives.
                  With their extensive experience and expertise, our trustee
                  provides invaluable guidance and strategic direction,
                  contributing to the overall growth and impact of our NGO. They
                  actively participate in decision-making processes, oversee
                  financial matters, and collaborate with stakeholders to forge
                  meaningful partnerships. Our trustee's unwavering commitment
                  to transparency, accountability, and ethical practices
                  inspires and motivates our team, while their tireless efforts
                  to advocate for our cause help us amplify our message and
                  drive positive change in our communities.
                </p>
              </div>
            </div>
          </div>
          <div class="row gy-4 our-volunteers justify-content-center">
            {trusteeList.map((data) => (
              <ProfileCard data={data} />
            ))}
          </div>
        </div>
      </section>
      <section class="our-volunteers-area pb-90">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="section-title text-center">
                <h2>
                  OUR <span>MANAGEMENT TEAM</span>
                </h2>
                <img src="/css/img/title-bottom.png" alt="" />
                <p>
                  Our team at Shree Motibhai R. Chaudhary Foundation is comprised of
                  dedicated individuals from diverse backgrounds who share a
                  common passion for making a positive impact in the world. From
                  experienced professionals to enthusiastic volunteers, our team
                  members bring their unique skills, expertise, and perspectives
                  to contribute towards our mission. With a deep commitment to
                  our cause, we collaborate closely, leveraging our collective
                  knowledge and creativity to tackle complex social and
                  environmental challenges. Guided by strong values of empathy,
                  integrity, and inclusivity, our team works tirelessly to
                  create sustainable solutions, raise awareness, and empower
                  communities.
                </p>
              </div>
            </div>
          </div>
          <div class="row gy-4 our-volunteers justify-content-center">
            {seniorManagementTeam.map((data) => (
              <ProfileCard data={data} />
            ))}
          </div>
          <div
            style={{ paddingTop: "20px" }}
            class="row gy-4 our-volunteers justify-content-center"
          >
            {volunteerList.map((data) => (
              <ProfileCard data={data} />
            ))}
          </div>
        </div>
      </section>
      <section class="video-and-skil-area pb-90">
        <div class="container">
          <div class="row gy-5">
            <div class="col-lg-6">
              <div class="about-video">
                <img src="/css/img/about/Lab.jpg" alt="" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-skill-text text-center">
                <h3>
                  <b>WHAT WE THINK ABOUT OUR CHARITY</b>
                </h3>
                <p>
                  At Shree Motibhai R. Chaudhary Foundation, we are incredibly
                  passionate about the work we do and the positive impact we
                  strive to make. Our charity is driven by a strong belief in
                  the power of collective action and the potential for change
                  when we come together.
                  <br />
                  <br />
                  <b>Transparency</b>, <b>accountability</b>, and{" "}
                  <b>efficiency</b> are the pillars of our organization. We
                  ensure that every donation and resource entrusted to us is
                  utilized effectively and reaches the beneficiaries it is
                  intended to help.
                  <br />
                  <br />
                  We are proud of the achievements we have made so far, but we
                  recognize that there is still much work to be done. With your
                  continued support, we can expand our reach, deepen our impact,
                  and create lasting change in the lives of individuals and
                  communities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="brand-logo-area pb-80">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="section-title text-center">
                <h2>
                  OUR <span>SPONSORS</span>
                </h2>
                <img src="/css/img/title-bottom.png" alt="" />
              </div>
            </div>
          </div>
          <div class="row gy-3 brand-logo justify-content-center">
            <div class="col-lg-12 align-self-center">
              <img src="/css/img/brand-logo/Logo.png" alt="Sponsor List" />
            </div>
            {/* {sponsorList.map((location) => (
              <SponsorCard data={location} />
            ))} */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Aboutus;
