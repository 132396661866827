import React from 'react';

function Footer() {
  return (
    <div className="main-area">
            <div class="footer-top pt-50 pb-50">
                <div class="footer-widget-area">
                    <div class="container">
                        <div class="row gy-4">
                        <div class="col-sm-6 col-lg-4">
                                <div class="footer-widget widget-one">
                                    <div class="footer-widget-title">
                                        <h3>ABOUT US</h3>
                                    </div>
                                    <div class="widget-about-content">
                                        <p>Shree Motibhai R. Chaudhary Foundation was registered on 10-02-1997 as Public Trust. Registration number is E/3892 Mehsana. </p>
                                    </div>
                                    <div class="about-button btn-lg-f bo-ra-3">
                  <a href="/#/about">Know More</a>
                </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <div class="footer-widget widget-two">
                                    <div class="footer-widget-title">
                                        <h3>QUCIK LINK</h3>
                                    </div>
                                    <nav>
                                        <ul>
                                            <li><a href="#/">Home</a></li>
                                            <li><a href="/#/about">About</a></li>
                                            <li><a href="/#/project">Project</a></li>
                                            <li><a href="/#/gallery">Gallery</a></li>
                                            <li><a href="/#/contactus">Contact Us</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <div class="footer-widget widget-two">
                                    <div class="footer-widget-title">
                                        <h3>CONTACT US</h3>
                                    </div>
                                    <div class="widget-contact-content">
                                        {/* <a href="98253 28328">Phone : 98253 28328</a> */}
                                        <p>Phone : 98253 28328</p>
                                        <p>Email :  motibhaichaudharyfoundation@gmail.com</p>
                                        <p>Address : “Moti Bhavan”, 1-8, Span Enclave,
                                            B/F Akshardham Township,
                                            Opp. N.G. International School, Vishnagar Road,
                                            Manav Ashram Chokadi, Mahesana, Gujarat 384001
                                            
                                            </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-sm-6 col-lg-4">
                                <div class="footer-widget widget-four">
                                    <div class="footer-widget-title">
                                        <h3>NEWSLETTER</h3>
                                    </div>
                                    <div class="widget-about-content">
                                        <p>Select your newsletters, enter your email address, and click "Subscribe"</p>
                                    </div>
                                    <div class="newslater">
                                        <form id="mc-form" class="mc-form form">
                                            <input id="mc-email" type="email" autocomplete="off" placeholder="Email Address" />
                                            <button id="mc-submit" type="submit"><i class="fa fa-envelope" aria-hidden="true"></i></button>
                                        </form>
                                        <div class="mailchimp-alerts text-centre">
                                            <div class="mailchimp-submitting"></div>
                                            <div class="mailchimp-success"></div>
                                            <div class="mailchimp-error"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="footer-bottom"> */}
                <div class="container">
                    {/* <div class="row">
                        <div class="col-md-8">
                            <div class="copyright">
                                <p> 
                                    <script>
                                        document.write(new Date().getFullYear() + ' ');
                                    </script> 
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="footer-social">
                                <nav>
                                    <ul>
                                        <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i class="fa fa-dribbble" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i class="fa fa-rss" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div> */}
                    <div class="" id="back-top"></div>
                </div>
            </div>
        //   </div>
  );
}

export default Footer;
