import React, { useEffect } from "react";
import GalleryCard from "./components/GalleryCard";

var sectorList = [
  {
    sectorName: "Water",
    sectorDescription:
      "Water is a vital natural resource that sustains life on Earth. Water conservation involves the responsible and sustainable use of water resources. It encompasses practices and strategies aimed at reducing water waste, promoting efficiency in water usage, and preserving water quality. It includes measures like using efficient irrigation systems, fixing leaks, practicing water-saving habits, and promoting awareness about the importance of water conservation.",
    donateAmount: "Rs. 25,000 / Rs. 50,000",
    imageLocation: "./css/img/couses/Water.jpg",
  },
  {
    sectorName: "Social Development",
    sectorDescription:
      "Social development involves creating conditions for individuals and communities to enhance their well-being, including education, income, gender equality, and social justice. It encompasses efforts to improve living standards, promote equal opportunities, foster inclusive societies, and ensure access to basic services, ultimately leading to overall societal progress. Join us in our mission of social development and help us build a brighter and more equitable future.",
    donateAmount: "Rs. 45,000 / Rs. 90,000",
    imageLocation: "./css/img/couses/SocialDevelopment.jpg",
  },
  {
    sectorName: "Health",
    sectorDescription:
      "Health encompasses physical, mental, and social well-being. It involves the prevention, treatment, and management of diseases and conditions, as well as the promotion of healthy lifestyles and environments. Health development focuses on providing accessible and quality healthcare services, addressing social determinants of health, and empowering individuals and communities to lead healthy lives. Join us for promoting health and building healthier communities. ",
    donateAmount: "Rs. 35,000 / Rs. 70,000",
    imageLocation: "./css/img/couses/Health.jpeg",
  },
  {
    sectorName: "Rural Development",
    sectorDescription:
      "Rural development is the process of improving living conditions, economic opportunities, and infrastructure in rural areas. It aims to address challenges like limited services and employment, promote agriculture, enhance infrastructure, provide healthcare and education, and empower local communities for sustainable and inclusive growth.",
    donateAmount: "Rs. 25,000 / Rs. 50,000",
    imageLocation: "./css/img/couses/RuralDevelopment.jpg",
  },
  {
    sectorName: "Women Empowerment",
    sectorDescription:
      "Women empowerment refers to the process of granting women equal rights, opportunities, and access to resources, education, and decision-making. It aims to challenge gender stereotypes, promote gender equality, enhance women's social, economic, and political participation, and create a more inclusive and equitable society.",
    donateAmount: "Rs. 45,000 / Rs. 90,000",
    imageLocation: "./css/img/couses/WomEmp.jpg",
  },
  {
    sectorName: "Animal Husbandry",
    sectorDescription:
      "Animal husbandry is the practice of rearing, breeding, and caring for animals, primarily livestock, for various purposes like food, fiber, and labor. It involves providing proper nutrition, healthcare, and housing to ensure animal welfare. It also includes selective breeding, disease control, and sustainable management of livestock resources.",
    donateAmount: "Rs. 35,000 / Rs. 70,000",
    imageLocation: "./css/img/couses/AnimalHusbandary.jpg",
  },
];

var galleryList = [
  {
    categories: ["SD"],
    imageLocation: "./css/img/gallery/SD04.jpg",
  },
  {
    categories: ["all", "Water"],
    imageLocation: "./css/img/gallery/WT01.jpg",
  },
  {
    categories: ["AH"],
    imageLocation: "./css/img/gallery/AH04.jpg",
  },
  {
    categories: ["SD"],
    imageLocation: "./css/img/gallery/SD03.jpg",
  },
  {
    categories: ["all", "WE"],
    imageLocation: "./css/img/gallery/WE01.jpg",
  },
  {
    categories: ["Water"],
    imageLocation: "./css/img/gallery/WT02.jpg",
  },
  {
    categories: ["AH"],
    imageLocation: "./css/img/gallery/AH03.jpg",
  },
  {
    categories: ["WE"],
    imageLocation: "./css/img/gallery/WE02.jpg",
  },
  {
    categories: ["SD"],
    imageLocation: "./css/img/gallery/SD02.jpg",
  },
  {
    categories: ["RD"],
    imageLocation: "./css/img/gallery/RD03.jpg",
  },
  {
    categories: ["WE"],
    imageLocation: "./css/img/gallery/WE03.jpg",
  },
  {
    categories: ["AH"],
    imageLocation: "./css/img/gallery/AH02.jpg",
  },
  {
    categories: ["Water"],
    imageLocation: "./css/img/gallery/WT03.jpg",
  },
  {
    categories: ["RD"],
    imageLocation: "./css/img/gallery/RD01.jpg",
  },
  {
    categories: ["WE"],
    imageLocation: "./css/img/gallery/WE04.jpg",
  },
  {
    categories: ["all", "AH"],
    imageLocation: "./css/img/gallery/AH01.jpg",
  },
  {
    categories: ["RD"],
    imageLocation: "./css/img/gallery/RD04.jpg",
  },
  {
    categories: ["Water"],
    imageLocation: "./css/img/gallery/WT04.jpg",
  },
  {
    categories: ["RD"],
    imageLocation: "./css/img/gallery/RD02.jpg",
  },
  {
    categories: ["all", "SD"],
    imageLocation: "./css/img/gallery/SD01.jpg",
  },
];

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "MRCF";
    window.jQuery("#gallery-filter").mixitup({
      effects: ["fade", "rotateZ"],
      easing: "snap",
    });
    window.jQuery("#ma-inivoslider-banner7").nivoSlider({
      effect: "random",
      slices: 15,
      boxCols: 8,
      boxRows: 4,
      animSpeed: "600",
      pauseTime: "6000000",
      startSlide: 0,
      directionNav: 1,
      controlNav: 0,
      controlNavThumbs: false,
      pauseOnHover: false,
      manualAdvance: false,
      prevText: "",
      nextText: "",
    });
  });
  return (
    <div className="main-area">
      <section style={{ backgroundColor: "#f1f1f1"}} className="about-content-area pt-90 pb-70">
        <div className="container">
          <div className="row gy-2">
            <div className="section-title text-center">
              <h2>
                <span>Late Shree Motibhai R. Chaudhary</span>
              </h2>
              <img src="./css/img/title-bottom.png" alt="" />
            </div>
            <div className="col-lg-8">
              <div className="about-content"><br/>
                <p>
                Shree Motibhai Chaudhary was born on July 3, 1923, in Manekpur village, Vijapur taluka. He had limited access to education but was endowed with extraordinary knowledge and self-confidence. In 1948, he joined the 'Sevadal' to actively participate in Mahatma Gandhi's post-independence public motivation drive.
                </p><br/>
                <p>
                He was later elected to the legislative assembly and served as Minister of Transportation in the state administration. His unrivalled wisdom, frugal attitude, and outstanding administrative abilities helped him to effectively oversee the 'Dudhsagar Dairy' for a record 35 years (1970 to 2005) as chairman. He was also known as "Bapu" among the Union's employees. He was elected to the Parliament for Agriculture, Dairy, and Rural Development and effectively settled several issues in his constituency. He gave many directives for the country's economic success.
                </p><br/>
                <p>
                  Shree Motibhai R. Chaudhary Foundation was established on February 10, 1997.
                On May 1, 2005, he died after a short spell of illness. Motibhai Chaudhary was a founder member of the Shree Motibhai R. Chaudhary Foundation.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="about-image" style={{ textAlign: "right" }}>
                {/* <img src="./css/img/about/padmashree.jfif" alt="" /> */}
                <img src="./css/img/MotibhaiRChaudhary.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="slider-area">
        <div class="ma-banner7-container">
          <div class="container-slider">
            <div class="flexslider ma-nivoslider d-flex justify-content-center">
              <div class="ma-loading"></div>
              <div id="ma-inivoslider-banner7" class="slides">
                <img
                  style={{ display: "none" }}
                  src="./css/img/slider/Home01.jpg"
                  alt=""
                  title="#banner7-caption1"
                />
              </div>
              <div
                id="banner7-caption1"
                class="banner7-caption nivo-html-caption nivo-caption"
              >
                <div class="banner7-content slider-1">
                  <h1
                    class="title1 wow slideInRight"
                    data-wow-duration="1s"
                    data-wow-delay="300ms"
                  >
                    Shree Motibhai R. Chaudhary Foundation
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-content-area pt-90 pb-70">
        <div className="container">
          <div className="row gy-2">
            <div className="section-title text-center">
              <h2>
                Our <span>Awards</span>
              </h2>

              <img src="./css/img/title-bottom.png" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <p>
                Maljibhai Desai received the <b>Padma Shri award</b>, the third highest honour in the Padma series of medals, from the Government of India in 2022 for his outstanding contributions in the field of public affairs. The award recognizes his work as a "Gandhian and veteran political leader promoting Khadi and Gandhian thought over five decades in public service."
                </p>
                <p>
                Maljibhai Devjibhai Desai (born 1938) is a Gujarati politician and Gandhian social activist. In 1964, he established the Gandhi Ashram in Zilia village in Gujarat's Patan district for the economic and educational development of Gujarat's rural population. Several educational institutions and initiatives for the economic development of the community have been implemented under the direction of the Ashram.
                </p>
                <p>Maljibhai Desai is a founding trustee of the Motibhai R. Chaudhary Foundation. He is currently working as president and offering leadership to a nonprofit organization.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-image" style={{ textAlign: "right" }}>
                {/* <img src="./css/img/about/padmashree.jfif" alt="" /> */}
                <img src="./css/img/about/Padmashree.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-couses-area pt-50 pb-90">
        <div className="container">
          <div className="row">
            {/* Start section title */}
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2>
                  OUR <span>SECTORS</span>
                </h2>
                <img src="./css/img/title-bottom.png" alt="" />
                <p>
                Our target sector is the precise area or subject on which our organization focuses its efforts. It includes water, social development, health, rural development, women's empowerment, and animal husbandry. We choose these areas based on our goal, expertise, and the needs of the communities we serve.
                </p>
              </div>
            </div>
            {/* End section title */}
          </div>
          <div className="row gy-4 couses">
            {sectorList.map((data, index) => (
              <Sector key={index} data={data} />
            ))}
          </div>
        </div>
      </section>
      <section className="help-us-area pt-80 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title-style-two text-center">
                <h2>HOW CAN YOU HELP US?</h2>
                <p>
                  There are many ways you can support our cause and make a
                  positive impact. You can volunteer your time and skills,
                  whether it's assisting in our programs, organizing events, or
                  offering professional expertise. Your monetary donations, no
                  matter the size, help us sustain and expand our initiatives.
                  Spread the word about our work through social media, sharing
                  our updates and campaigns. Participate in our fundraising
                  events or create your own to raise funds for our projects.
                  In-kind donations, such as supplies or equipment, are also
                  greatly appreciated. Lastly, advocate for our cause in your
                  community, raising awareness and inspiring others to join us
                  in making a difference. Together, we can create lasting
                  change.
                </p>
              </div>
            </div>
          </div>
          <div className="row gy-4 help-us-content-area">
            {/* Start single help */}
            <div className="col-md-6 col-sm-6">
              <div className="single-help">
                <div className="help-icon">
                  <i className="fa fa-university" aria-hidden="true" />
                </div>
                <div className="help-text">
                  <h3>Donator</h3>
                  <p>
                    Your contribution plays a vital role in our mission to make
                    a positive impact on the lives of those in need. By donating
                    to our organization, you are directly supporting our
                    projects and initiatives aimed at addressing pressing social
                    issues.
                    <br />
                    We value every donation, no matter the size, as it
                    represents a step towards creating a more equitable and
                    compassionate world.
                    <br />
                    Join us today as a donator and help us continue our work of
                    transforming lives and building a brighter future for all.
                    Together, we can make a lasting difference.
                  </p>
                  {/* <a href="donate.html" className="btn-sm bo-ra-3">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
            {/* End single help */}
            {/* Start single help */}
            <div className="col-md-6 col-sm-6">
              <div className="single-help">
                <div className="help-icon">
                  <i className="fa fa-users" aria-hidden="true" />
                </div>
                <div className="help-text">
                  <h3>Volunteer</h3>
                  <p>
                    Your time and skills can make a significant impact on the
                    lives of those we serve. As a volunteer, you have the
                    opportunity to contribute to our projects, events, and
                    initiatives, working closely with our dedicated team. <br />
                    Whether you have a few hours to spare or are looking for a
                    long-term commitment, there are various ways to get
                    involved. <br />
                    Join our team of passionate volunteers today and be part of
                    our mission to make a difference.{" "}
                  </p>
                  {/* <a
                    href="elements-keep-in-touch.html"
                    className="btn-sm bo-ra-3"
                  >
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
            {/* End single help */}
          </div>
        </div>
      </section>
      <section className="our-gallery pt-90 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2>
                  OUR <span>Gallery</span>
                </h2>
                <img src="./css/img/title-bottom.png" alt="" />
                <p>
                  Our gallery section showcases the inspiring moments and
                  impactful work of our NGO. These images capture our
                  initiatives, events and the faces of change. <br />
                  Witness the transformation we are bringing to communities and
                  the smiles we are spreading.
                </p>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="gallery-menu text-center">
                <nav>
                  <ul>
                    <li class="filter" data-filter="all">
                      All
                    </li>
                    <li class="filter" data-filter="Water">
                      Water
                    </li>
                    <li class="filter" data-filter="SD">
                      Social Development
                    </li>
                    <li class="filter" data-filter="Health">
                      Health
                    </li>
                    <li class="filter" data-filter="RD">
                      Rural Development
                    </li>
                    <li class="filter" data-filter="WE">
                      Women Empowerment
                    </li>
                    <li class="filter" data-filter="AH">
                      Animal Husbandry
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row" id="gallery-filter">
            {galleryList.map((data, index) => (
              <GalleryCard key={index} data={data} />
            ))}
          </div>
        </div>
      </section>
      <section className="our-gallery pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2>
                  Upcomming <span>Events</span>
                </h2>
                <img src="./css/img/title-bottom.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <img src="/css/img/Upcoming04.jpeg" />
            </div>
            {/* <div className="col-lg-4">
              <img src="/css/img/Upcoming03.jpeg" />
            </div> */}
            {/* <div className="col-lg-4">
              <img src="/css/img/Upcoming02.jpg" />
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className="countdown-area pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-lg-4 te">
              <div className="countdown-side-area">
                <div className="countdown-icon">
                  <i className="fa fa-calendar-check-o" aria-hidden="true" />
                </div>
                <div className="countdown-text">
                  <h3>Up comming event</h3>
                  <p>BLOOD DONATION CAMP</p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="people-says-area pt-54 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="people-asy-title text-center">
                <h2>WHAT PEOPLE SAYS</h2>
                <img src="./css/img/title-bottom.png" alt="" />
              </div>
              <div className="people-say-slide">
                <div className="single-people text-center">
                  <div className="people-say-image">
                    <img src="img/testimonial.jpg" alt="" />
                  </div>
                  <div className="peoplp-say-text text-center">
                    <p>Brief text on appreciation of MRCF</p>
                    <h5>Name</h5>
                    <h6>Position</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

const Sector = ({ data }) => {
  return (
    <div className="col-md-4">
      <div className="single-couses">
        <div className="single-couses-image-text">
          <div className="couses-image">
            <img src={data.imageLocation} alt="" />
          </div>
          <div className="couses-text text-center">
            <h3>{data.sectorName}</h3>
            <p>{data.sectorDescription}</p>
          </div>
        </div>
        <div className="couses-button-and-price">
          <div className="couses-button">
            <a href="/#/donate" className="btn-md bo-ra-5">
              Donate
            </a>
          </div>
          <div className="couses-price">
            <h5>{data.donateAmount}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
