const BlogCard = ({data}) => {
    return (
        <><div class="col-lg-4 col-md-6">
        <div class="single-blog mb-30">
            <div class="blo-image-and-date">
                <img src="/css/img/event/event-four.png" alt="" />
                <a href="#">{data.date}</a>
            </div>
            <div class="blog-text">
                <h3><a href="#">{data.title}</a></h3>
                <p>{data.description}</p>
                {/* <a href="#">Read More...</a> */}
            </div>
        </div>
    </div></>
    )
}

export default BlogCard;