const GalleryCard = ({ data }) => {
    var classname = "col-md-3 col-6 mb-30 single-gallery mix "
    data.categories.map((category) => {
        classname += category
        classname += " "
    })
  return (
    <>
      <div className={classname}>
        <div className="gallery-image-and-text">
          <img className="img-responsive" src={data.imageLocation} alt="" />
        </div>
      </div>
    </>
  );
};

export default GalleryCard;
