import BlogCard from "./components/BlogCard";

var blogList = [{
    date: "24 May",
    title: "100 Shatabdi Mohotsav Program",
    description: "TEMP"
}]

const Blog  = () => {
    return (
        <>
         <section class="page-title-area">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 position-relative">
                        <div class="page-title text-center">
                            <h1>Our Events</h1>
                        </div>
                        <div class="breadcrumbs-area  text-center">
                            <ol class="breadcrumbs">
                                <li><a href="#">Home</a></li>
                                <li class="active">Events</li>
                            </ol>
                        </div>
                        <div class="back-home">
                            <a href="index.html"><i class="fa fa-arrow-left" aria-hidden="true"></i> BACK TO HOME</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="our-blog-area pt-90 pb-60">
            <div class="container">
                <div class="row justify-content-center">
                    {blogList.map((data, index) => <BlogCard data={data} key={index} />)}
                </div>
            </div>
        </section>
        </>
    )
}

export default Blog;