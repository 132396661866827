const ProfileCard = ({data}) => {
    return (<div class="col-lg-3 col-sm-6 col-6">
    <div class="single-volunteer">
        <div class="volunteer-image-and-social">
            <img src={data.imageLocation} alt="" />
            {/* <div class="volunteer-social">
                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                <a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
            </div> */}
        </div>
        <div class="volunteer-title text-center">
            <h5>{data.name}</h5>
            <p>{data.position}</p>
        </div>
    </div>
</div>)
}

export default ProfileCard;