import { useEffect } from "react";

const Donate = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'MRCF | Donate';
    },[])
    return (
        <>
        <section class="page-title-area">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 position-relative">
                        <div class="page-title text-center">
                            <h1>Donate</h1>
                        </div>
                        <div class="breadcrumbs-area  text-center">
                            <ol class="breadcrumbs">
                                <li><a href="#">Home</a></li>
                                <li class="active">Donate</li>
                            </ol>
                        </div>
                        <div class="back-home">
                            <a href="index.html"><i class="fa fa-arrow-left" aria-hidden="true"></i> BACK TO HOME</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="donate-page-area pt-90 pb-80">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="donate-content">
                            <div class="donate-form-container">
                                <div class="donate-form-title">
                                    <h3>Bank Details</h3>
                                </div>
                                <div class="donation-form quick-donation-section donate-page">
                                    Name of Account Holder: Shree Motibhai R. Chaudhary Foundation<br/>
                                    Bank Name: Bank of Baroda<br/>
                                    Branch Name: Manav Ashram Chokadi, Gujarat<br/>
                                    Address of Bank: Shop No: 3-6, Raj Shree Complex, Manav Ashram Chokadi, Visnagar Road, Mahesana<br/>
                                    Telephone No.: 02762 - 220404<br/>
                                    Account Number: 01520100030441<br/>
                                    MICR No.: 384012005<br/>
                                    IFS Code: BARB0MANMEH<br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="donate-content">
                            <div class="donate-form-container">
                                <div class="donate-form-title">
                                    <h3>QR Code</h3>
                                </div>
                                <div class="donation-form quick-donation-section donate-page">
                                    <img id="qr_style" src="/css/img/QR_Code.jpeg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Donate;