import React, {useEffect} from 'react';
import { Link } from "react-router-dom"
import logo from './logo.png';

function Navbar() {
    useEffect(() => {
        window.jQuery('nav#dropdown').meanmenu({
            meanMenuOpen: '<span class="menu-bar"></span>'
        });
    }, []);
  return (
    <div className="main-area">
      <header>
        		<div class="main-menu-area" id="sticker">
        			<div class="container">
        				<div class="row">
        					<div class="col-md-3 col-6">
        						<div class="logo ptb-32">
        							<a href="/">
        								<img src={logo} alt="" />
        							</a>
        						</div>
        					</div>
        					<div class="col-md-9 col-6 position-relative">
                                <div class="main-menu">
                                    <nav>
                                        <ul>
                                            <li><a href="#/">Home</a></li>
                                            <li><a href="/#/about">About</a></li>
                                            <li><a href="/#/project">Project</a></li>
                                            {/* <li><a href="/#/events">Events</a></li> */}
                                            <li><a href="/#/gallery">Gallery</a></li>
                                            <li><a href="/#/contactus">Contact Us</a></li>
                                            {/* <li><a href="/#/donate">Donate</a></li> */}
                                            
                                        </ul>
                                    </nav>
                                </div>
                                <div class="donate-button ptb-32">
                                    <a class="waves-effect waves-light" href="/#/donate">Donate</a>
                                </div>
                            </div>
        				</div>
        			</div>
        		</div>
                <div class="mobile-menu-area">
                    <div class="container">
                        <div class="row">
                            <div class="mobile-menu">
                                <nav id="dropdown">
                                    <ul class="nav">
                                        <li><a class="home" href="#">Home</a></li>
                                        <li><a href="/#/about">About</a></li>
                                        <li><a href="/#/project">Project</a></li>
                                        <li><a href="/#/gallery">Gallery</a></li>
                                        <li><a href="/#/contactus">Contact Us</a></li>
                                        {/* <li><a href="/#/donate">Donate</a></li> */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
        	</header>
    </div>
  );
}


export default Navbar;
