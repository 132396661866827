import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router, Route, Routes } from "react-router-dom"
import Navbar from './Navbar';
import Home from './Home';
import Aboutus from './Aboutus';
import Footer from './Footer';
import ErrorPage from './ErrorPage';
import reportWebVitals from './reportWebVitals';
import Project from './Project';
import Contactus from './Contactus';
import Donate from './Donate';
import Gallery from './Gallery';
import Blog from './Blog';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <>
    <Navbar />    
     <Router>
          {/* <Route path="/motibhaichaudharyfoundation" element={<Home />}/> */}
          <Routes>
          <Route exact path="/" Component={Home}/>
          {/* </Route> */}
          {/* <Route path="/motibhaichaudharyfoundation/about" element={<Aboutus />}> */}
          <Route path="/about" Component={Aboutus}/>
          <Route path="/project" Component={Project}/>
          <Route path="/contactus" Component={Contactus}/>
          <Route path="/donate" Component={Donate}/>
          <Route path="/events" Component={Blog}/>
          <Route path="/gallery" Component={Gallery}/>
          <Route path="/*" Component={ErrorPage}/>
          {/* </Route> */}
          </Routes>
      </Router>
    
    
    
    <Footer />
    {/* <EventCard
    imageSrc="/img/couses/2013EduTour.png"
    date="JAN 2022"
    title="Well Deepening Work in Amirgarh Taluk"
    time="10:00 - 05:00"
    location="New Jorgia"
    /> */}
  {/* </React.StrictMode> */}
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
